import '../css/main.scss';

import 'jquery-validation';
$.validator.unobtrusive = require('jquery-validation-unobtrusive');
import 'bxslider/dist/jquery.bxslider';
import 'jquery-colorbox';
import 'jssocials';

/* Bootstrap modules - comment out as required */
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import { SetActiveStyleSheet } from '../../../Components/Base/BaseComponent/Content/components/StyleSwitcher';

import '../../../Components/Base/BaseComponent/Content/components/CKReadMore';
import '../../../Components/Base/BaseComponent/Content/components/MapOffices';
import '../../../Components/Base/BaseComponent/Content/components/AjaxPage';

import { InitialiseForElement as AjaxFormInitialise } from '../../../Components/Base/BaseComponent/Content/components/PartialHost';
import { Initialise as RecaptchaFormInitialise } from '../../../Components/Base/BaseComponent/Content/components/RecaptchaForm';

document.body.classList.remove("no-js");
document.body.classList.add("js");

const targetWidth = 260;
const slideMargin = 80;

let slider: bxSlider;

function setupEventTracking() {
    $("#page-header-phone a").click(function () {
        ga("gtm1.send", "event", "Phone Numbers", "Click", `Header ${$(this).text()}`);
    });

    $("#page-header-email a").click(function () {
        ga("gtm1.send", "event", "Email Addresses", "Click", `Header ${$(this).text()}`);
    });

    $("a.link-youtube").click(function () {
        ga("gtm1.send", "event", "Videos", "Click", "Played Video");
    });

    $("a.home-slide").click(function () {
        ga("gtm1.send", "event", "CTA", "Click", `Slider ${$(this).attr("href")}`);
    });

    if (location.pathname === "/contact/") {
        $("#page-content a").click(function () {
            ga("gtm1.send", "event", "Phone Numbers", "Click", `Contact Page ${$(this).text()}`);
        });
    }
}

function determineSlideWidth(): number {
    const wrap = document.getElementById("home-customers") as HTMLElement;
    if (wrap == null) {
        return 0;
    }

    const count = Math.round(wrap.clientWidth / targetWidth);
    if (count === 1) {
        return wrap.clientWidth;
    }
    const width = Math.floor((wrap.clientWidth - (slideMargin * (count - 0.5))) / count);
    return width;
}

function generateOptions(currentSlide = 0): bxSliderOptions {
    return {
        slideMargin: slideMargin,
        minSlides: 1,
        maxSlides: 4,
        moveSlides: 1,
        slideWidth: determineSlideWidth(),
        auto: true,
        autoStart: true,
        pause: 4000,
        pager: false,
        startSlide: currentSlide,
        prevText: "\uf100",
        nextText: "\uf101"
    };
}

function resizeSlider() {
    if (slider == null) {
        return;
    }

    const currentSlide = slider.getCurrentSlide();
    slider.destroySlider();

    slider = $("#customers-carousel").bxSlider(generateOptions(currentSlide));
}

function toggleStickyHeaderClass()
{
    const header = document.getElementById("page-header");
    if (!header)
    {
        return;
    }

    const activeSticky = window.scrollY > 0 && window.innerWidth > 992;
    header.classList.toggle("page-header-stuck", activeSticky);
}

function setupStickyHeader()
{
    const header = document.getElementById("page-header");
    if (!header)
    {
        return;
    }

    document.addEventListener("scroll", toggleStickyHeaderClass, { passive: true });
}

$(function ()
{
    $(".link-youtube").colorbox({
        iframe: true,
        width: "800px",
        height: "496px",
        maxHeight: "80%",
        maxWidth: "80%"
    });

    $("#home-slider .bxslider").bxSlider({
        auto: true,
        autoStart: true,
        pause: 4000,
        pager: false,
        controls: false
    });

    $(".news-category-dropdown").click(function (ev)
    {
        if (ev.target.classList.contains("news-category-dropdown"))
        {
            ev.preventDefault();
            $(this).toggleClass("active");
        }
    });

    slider = $("#customers-carousel").bxSlider(generateOptions());

    window.addEventListener("resize", resizeSlider);

    const forms = document.querySelectorAll("form[data-ajax]");
    for (let i = 0; i < forms.length; i++)
    {
        if ((forms[i] as HTMLFormElement).dataset.recaptcha)
        {
            RecaptchaFormInitialise(forms[i] as HTMLFormElement);
        }
        else
        {
            AjaxFormInitialise(forms[i] as HTMLFormElement);
        }
    }

    setupStickyHeader();
    setupEventTracking();

    $("[dbs-set-stylesheet]").click(function (ev)
    {
        ev.preventDefault();
        const targetStylesheet = $(this).attr("dbs-set-stylesheet");
        if (targetStylesheet)
        {
            SetActiveStyleSheet(targetStylesheet);
        }
    });
});